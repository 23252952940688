import { ContentfulMovie } from '../../../model/generated/graphql';
import React, { FC } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import Prev from '../../../../static/icons/left-arrow-point.svg';
import Next from '../../../../static/icons/right-arrow-point.svg';
import { pxToRem } from '../../../utils/pxToRem';
import LocaleLink from '../../../components/LocaleLink/LocaleLink';
import { ROUTES } from '../../../utils/routes';
export type Locale = 'en' | 'pl';
interface MovieNavProps {
    activeLocale: Locale;
    prevEntry: ContentfulMovie;
    nextEntry: ContentfulMovie;
}
export const MovieNav: FC<MovieNavProps> = ({ activeLocale, prevEntry, nextEntry }) => {
    return (
        <nav
            style={{
                position: 'absolute',
                bottom: pxToRem(50),
                left: pxToRem(50),
                zIndex: 1,
            }}
        >
            <span style={{ width: 50, display: 'inline-block' }}>
                {prevEntry && (
                    <motion.span whileHover={{ scale: 0.9 }} style={{ display: 'inline-block' }}>
                        <LocaleLink to={`/${ROUTES.PROJECTS}/${prevEntry.slug}`}>
                            <Prev width={50} height={50} fill={'#fff'} />
                        </LocaleLink>
                    </motion.span>
                )}
            </span>
            {nextEntry && (
                <motion.span whileHover={{ scale: 0.9 }} style={{ display: 'inline-block' }}>
                    <LocaleLink to={`/${ROUTES.PROJECTS}/${nextEntry.slug}`}>
                        <Next width={50} height={50} fill={'#fff'} />
                    </LocaleLink>
                </motion.span>
            )}
        </nav>
    );
};
