import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { motion, Variants } from 'framer-motion';
import { ContentfulMovie } from '../../../model/generated/graphql';

import LocaleLink from '../../../components/LocaleLink/LocaleLink';
import { pxToRem } from '../../../utils/pxToRem';

const dot: Variants = {
    enter: {
        scale: 5,
        boxShadow: '0 0px 0px rgba(0, 0, 0, 0), 0 0px 0px rgba(0, 0, 0, 0)',
    },
    leave: (active) => ({
        scale: active ? 2 : 1,
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
        transition: { delay: 0.2 },
    }),
};

const label: Variants = {
    enter: {
        opacity: 1,
        clipPath: 'inset(-50% -50% -50% -50% round 20px 0 0 20px)',
        transition: { delay: 0.2, opacity: { ease: 'easeOut' } },
    },
    leave: {
        opacity: 0,
        clipPath: 'inset(0% 0% 0% 100% round 20px 0 0 20px)',
        transition: { opacity: { ease: 'easeIn' } },
    },
};

interface Props {
    data: ContentfulMovie[];
    currentPage: string;
}

export const DotsIndicator: FC<Props> = ({ data, currentPage }) => {
    return (
        <Wrapper>
            <DotsList>
                {data.map((item) => {
                    const active = currentPage === item.id;
                    return <Dot data={item} key={item.id} active={active} />;
                })}
            </DotsList>
        </Wrapper>
    );
};

interface DotProps {
    data: ContentfulMovie;
    active: boolean;
}

const Dot: FC<DotProps> = ({ data, active }) => {
    const { title, slug } = data;
    const [hover, setHover] = useState(false);

    return (
        <DotsItem
            whileHover={{
                marginTop: '30px',
                marginBottom: '30px',
            }}
            onHoverStart={() => setHover(true)}
            onHoverEnd={() => setHover(false)}
        >
            <LocaleLink to={`/projects/${slug}`}>
                <DotShape variants={dot} initial="leave" animate={hover ? 'enter' : 'leave'} custom={active} />
                <LinkWrapper
                    style={{ clipPath: 'inset(0 0 0 100% round 50% 0 0 50%)' }}
                    variants={label}
                    animate={hover ? 'enter' : 'leave'}
                >
                    {title}
                </LinkWrapper>
            </LocaleLink>
        </DotsItem>
    );
};

const LinkWrapper = styled(motion.div)`
    position: absolute;
    top: ${pxToRem(-8)};
    right: ${pxToRem(17)};
    white-space: nowrap;
    background-color: white;
    padding: ${pxToRem(11)} ${pxToRem(40)} ${pxToRem(11)} ${pxToRem(50)};
    border-bottom-left-radius: ${pxToRem(20)};
    border-top-left-radius: ${pxToRem(20)};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: -1;
    color: black;
    font-weight: 500;
    //font-family: "Montserrat";
    //font-size: 0.8rem;
`;

const DotsList = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
`;

const DotsItem = styled(motion.li)`
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: flex-end;
    min-width: ${pxToRem(30)};
    padding-right: ${pxToRem(12)};
`;

const DotShape = styled(motion.div)`
    width: ${pxToRem(8)};
    height: ${pxToRem(8)};
    background-color: white;
    border-radius: 50%;
    margin: ${pxToRem(8)} 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const Wrapper = styled.div`
    position: absolute;
    right: ${pxToRem(28)};
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    @media (max-width: ${pxToRem(800)}) {
        display: none;
    }
`;
