import React, { FC, useContext, useRef, useState, WheelEvent } from 'react';
import { graphql, navigate, PageProps } from 'gatsby';
import { motion, Variants } from 'framer-motion';
import { throttle, debounce } from 'throttle-debounce';
import { ContentfulCategoryMoviesConnection, ContentfulMovie } from '../model/generated/graphql';
import LocaleContext from '../context/Locale';
import { Section } from '../modules/movies/components/Section';
import { MovieNav } from '../modules/movies/components/MovieNav';
import { DotsIndicator } from '../modules/movies/components/DotsIndicator';
import { Notch } from '../components/Notch';
import { useDimensions } from '../hooks/useDimenstions';

type MovieQueryProps = {
    contentfulMovie: ContentfulMovie;
    allContentfulCategoryMovies: ContentfulCategoryMoviesConnection;
};
type MoviePageProps = PageProps<MovieQueryProps>;

const transition = {
    duration: 1,
    ease: [0.43, 0.13, 0.23, 0.96],
};

function getPrevAndNextEntry(entries, slug) {
    const currentEntryIndex = entries.findIndex((item) => item.slug === slug);
    const entriesArray = Object.values(entries);
    let prevEntry;
    let nextEntry;
    if (currentEntryIndex < entriesArray.length - 1) {
        nextEntry = entriesArray[currentEntryIndex + 1];
    }
    if (currentEntryIndex > 0) {
        prevEntry = entriesArray[currentEntryIndex - 1];
    }
    return [prevEntry, nextEntry];
}

const coverVariants: Variants = {
    initial: (width) => {
        console.log(width);
        return {
            clipPath: width > 700 ? 'circle(55% at 70% 50%)' : 'circle(35% at 70% 50%)',
            opacity: 1,
            transition: { ...transition },
        };
    },
    hover: {
        clipPath: 'circle(20% at 30% 50%)',
        opacity: 1,
        transition: { ...transition },
    },
    visible: (i) => ({
        opacity: 1,
        transition: {
            delay: i * 0.3,
        },
    }),
    exit: {
        clipPath: 'circle(100% at 50% 50%)',
        opacity: 0,
        transition: { opacity: { ...transition, delay: 0.5 } },
    },
};

const nextVariants: Variants = {
    exit: {
        clipPath: [
            'circle(55% at 70% 50%)',
            'circle(15% at 50% 50%)',
            'circle(15% at 50% 50%)',
            'circle(55% at 70% 50%)',
        ],
        transition: { duration: 2 },
    },
};

export const MovieTemplate: FC<MoviePageProps> = ({ data }) => {
    const { activeLocale } = useContext(LocaleContext);
    const { slug, id } = data.contentfulMovie;
    const movies = data.allContentfulCategoryMovies.nodes[0].movies;

    const [prevEntry, nextEntry] = getPrevAndNextEntry(movies, slug);
    const [explore, setExplore] = useState(false);
    const [exitType, setExitType] = useState(nextVariants);

    const handleDetails = () => {
        setExitType(coverVariants);
        setExplore(!explore);
    };

    const NavByScroll = async (e) => {
        const navigateTo = nextEntry && e.deltaY > 0 ? nextEntry : prevEntry && e.deltaY < 0 ? prevEntry : undefined;
        if (navigateTo) {
            await navigate(`/${activeLocale}/projects/${navigateTo.slug}`);
        }
    };

    // const throttleNavigation = debounce(2000, async (e: WheelEvent<HTMLDivElement>) => NavByScroll(e), {
    //     atBegin: true,
    // });

    const throttleNavigation = throttle(100, async (e: WheelEvent<HTMLDivElement>) => NavByScroll(e), {
        debounceMode: false,
        noTrailing: false,
        noLeading: false,
    });

    // const throttleNavigation = debounce(
    //     2000,
    //     async (e: WheelEvent<HTMLDivElement>) => {
    //         const navigateTo =
    //             nextEntry && e.deltaY > 0 ? nextEntry : prevEntry && e.deltaY < 0 ? prevEntry : undefined;
    //         if (navigateTo) {
    //             await navigate(`/${activeLocale}/movies/${navigateTo.slug}`);
    //         }
    //     },
    //     { atBegin: true },
    // );

    const onWheel = (e: WheelEvent<HTMLDivElement>): void => {
        console.log(e);
        throttleNavigation(e);
    };

    return (
        <motion.div initial="initial" animate="animate" exit="exit" key="movieEntry2">
            <Notch movies={movies} activeLocale={activeLocale} />
            <DotsIndicator data={movies} currentPage={id} />
            <MovieNav nextEntry={nextEntry} prevEntry={prevEntry} activeLocale={activeLocale} />

            <div style={{ overflow: 'hidden' }}>
                <Section
                    handleOnWheel={throttleNavigation}
                    movie={data.contentfulMovie}
                    handleDetails={handleDetails}
                    exitType={exitType}
                    explore={explore}
                />
            </div>
        </motion.div>
    );
};

export default MovieTemplate;

export const pageQuery = graphql`
    query MovieBySlug($node_locale: String, $slug: String) {
        contentfulMovie(node_locale: { eq: $node_locale }, slug: { eq: $slug }) {
            id
            title
            slug
            description {
                childMarkdownRemark {
                    html
                }
            }
            cover {
                gatsbyImageData(width: 1920, height: 1080, quality: 90, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            cover2 {
                gatsbyImageData(width: 800, height: 1070, quality: 90, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            gallery {
                gatsbyImageData(layout: FIXED, placeholder: BLURRED, quality: 90, width: 200)
            }
        }
        allContentfulCategoryMovies(filter: { node_locale: { eq: $node_locale } }) {
            nodes {
                id
                title
                movies {
                    id
                    title
                    slug
                    movieInfo {
                        releaseDate
                    }
                    status
                    cover {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            width: 600
                            height: 280
                            cropFocus: CENTER
                            quality: 90
                            formats: [AUTO, WEBP]
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`;
